import {
  UserManager,
  WebStorageStateStore,
  User,
  UserManagerSettings,
} from "oidc-client-ts";
import jwtDecode from "jwt-decode";
import axios from "axios";

export default class AIMService {
  private userManager: UserManager;

  constructor() {
    const AUTH0_DOMAIN: string = process.env.VUE_APP_OPEN_ID_DOMAIN;
    const client_id: string = process.env.VUE_APP_CLIENT_ID;
    const ISSUER: string = process.env.VUE_APP_OPEN_ID_ISSUER;

    const ROOT_URL = `${window.location.protocol}//${window.location.host}${process.env.BASE_URL}`;

    const settings: UserManagerSettings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: `${AUTH0_DOMAIN}`,
      client_id: client_id,
      redirect_uri: ROOT_URL,
      automaticSilentRenew: true,
      loadUserInfo: true,
      silent_redirect_uri: ROOT_URL + "silent.html",
      response_type: "code",
      scope: "email openid",
      post_logout_redirect_uri: ROOT_URL,
      filterProtocolClaims: true,
      metadata: {
        issuer: ISSUER,
        authorization_endpoint: AUTH0_DOMAIN + "/oauth2/authorize",
        userinfo_endpoint: AUTH0_DOMAIN + "/oauth2/userInfo",
        end_session_endpoint:
          AUTH0_DOMAIN +
          `/logout?client_id=${client_id}
          &logout_uri=${ROOT_URL}`,
        token_endpoint: AUTH0_DOMAIN + "/oauth2/token",
      },
    };

    this.userManager = new UserManager(settings);
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  public signinRedirectCallback(): Promise<User> {
    return this.userManager.signinRedirectCallback();
  }

  public getRoles(): Promise<Array<string>> {
    const roles: Array<string> = [];
    return new Promise(() => roles);
    //TODO replace this with groups when we add Groups table to DB
    // return this.getAccessToken().then((token) => {
    //   return token["cognito:groups"];
    // });
  }

  public async isAdmin(): Promise<boolean> {
    const user = await this.getUser();
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_ADDR}/users/${user?.profile.sub}`
      );
      return response.data.isAdmin;
    } catch (e) {
      console.dir(e);
      return false;
    }

    // return this.getAccessToken().then((token) => {
    //   if (
    //     token["cognito:groups"].includes("admin") ||
    //     token["cognito:groups"].includes("navigov_admin")
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
  }

  public getAccessToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(function (user: User | null) {
          if (user) return resolve(jwtDecode(user.access_token));
          else return undefined;
        })
        .catch((error) => reject(error));
    });
  }

  public isLoggedIn(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(function (user: User | null) {
          if (user && user != null && !user.expired) {
            return resolve(true);
          } else {
            return resolve(false);
          }
        })
        .catch(() => reject(false));
    });
  }

  public getRawAccessToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(function (user: User | null) {
          if (user) return resolve(user.access_token.toString());
          else return undefined;
        })
        .catch((error) => reject(error));
    });
  }
}

export const aimService = new AIMService();
