import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import WelcomeView from "@/views/WelcomeView.vue";
import QuestionnaireView from "@/views/QuestionnaireView.vue";
import ResultsView from "@/views/ResultsView.vue";
import NoResultsView from "@/views/NoResultsView.vue";
import SSOCallback from "@/views/SSOCallback.vue";
import UserResultsView from "@/views/UserResultsView.vue";
import AdminAllResultsView from "@/views/AdminAllResultsView.vue";
import AdminUserManagement from "@/views/AdminUserManagement.vue";
import ReadOnlyQuestionnaireView from "@/views/ReadOnlyQuestionnaireView.vue";

import { aimService } from "../services/AIMService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "welcome",
    component: WelcomeView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      roles: [],
    },
  },
  {
    path: "/userResults",
    name: "userResults",
    component: UserResultsView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      roles: [],
    },
  },
  {
    path: "/questionnaire/:id",
    name: "questionnaire",
    component: QuestionnaireView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      roles: [],
    },
  },
  {
    path: "/results/:id",
    name: "results",
    component: ResultsView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      roles: [],
    },
  },
  {
    path: "/noResultsFound",
    name: "noResults",
    component: NoResultsView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      roles: [],
    },
  },
  {
    path: "/sso-callback",
    component: SSOCallback,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/adminAllResults",
    name: "adminAllResults",
    component: AdminAllResultsView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      roles: ["admin", "navigov_admin"],
    },
  },
  {
    path: "/adminUserManagement",
    name: "adminUserManagement",
    component: AdminUserManagement,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      roles: ["admin", "navigov_admin"],
    },
  },
  {
    path: "/questionnaireReview/:id",
    name: "questionnaireReview",
    component: ReadOnlyQuestionnaireView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      roles: [],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.meta) {
    if (!to.meta.requiresAuth) {
      next();
    } else if (to.meta.requiresAuth && !aimService.isLoggedIn()) {
      next("/#");
    } else {
      if (aimService) {
        if (to.meta.requiresAdmin)
          (await aimService.isAdmin()) ? next() : next("/#");
        else next();
        // aimService
        //   .getRoles()
        //   .then((roles: Array<string>) => {
        //     if (
        //       (to.meta.roles && to.meta.roles.length == 0) ||
        //       roles.some((item: string) =>
        //         to.meta.roles ? to.meta.roles.includes(item) : false
        //       )
        //     ) {
        //       next();
        //     } else {
        //       next("/#");
        //     }
        //   })
        //   .catch((error) => {
        //     console.dir(error);
        //   });
      } else {
        next();
      }
    }
  }
});

export default router;
