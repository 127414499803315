import { SurveyModel } from "survey-jquery";

/**
 * A Questionnaire object
 */
export class Questionnaire {
  uuid: string;
  id: number;
  surveyModel: SurveyModel;
  indicatorCalculation: JSON;
  indicatorTranslator: JSON;

  constructor(questionnaire: Questionnaire) {
    this.uuid = questionnaire.uuid;
    this.id = questionnaire.id;
    this.surveyModel = questionnaire.surveyModel;
    this.indicatorCalculation = questionnaire.indicatorCalculation;
    this.indicatorTranslator = questionnaire.indicatorTranslator;
  }
}

/**
 * A Translation string.
 *
 * Each class variable holds a string value in the language of the variable name.
 */
export class TranslationString {
  default: string;
  en: string;
  fr: string;
  constructor(translationString: TranslationString) {
    this.default = translationString.default;
    this.en = translationString.en;
    this.fr = translationString.fr;
  }
}

/**
 * An IndicatorOutput object
 */
export class IndicatorOutput {
  uuid: string;
  questionnaireTitle: TranslationString;
  userTitle: string;
  indicatorValues: {
    title: string;
    titles: TranslationString;
    score: number;
    notes: TranslationString;
  }[];
  recommendations: {
    title: string;
    notes: TranslationString;
  }[];
  gettingStarted: {
    title: string;
    notes: TranslationString;
  }[];
  pillars: {
    title: string;
    titles: TranslationString;
    score: number;
  }[];
  isInterim: boolean;

  constructor(indicatorOutput: IndicatorOutput) {
    this.uuid = indicatorOutput.uuid;
    this.questionnaireTitle = indicatorOutput.questionnaireTitle;
    this.userTitle = indicatorOutput.userTitle;
    this.indicatorValues = indicatorOutput.indicatorValues;
    this.recommendations = indicatorOutput.recommendations;
    this.gettingStarted = indicatorOutput.gettingStarted;
    this.pillars = indicatorOutput.pillars;
    this.isInterim = indicatorOutput.isInterim;
  }
}

export class UserResultReference {
  uuid: string;
  questionnaireTitle: string;
  userTitle: string;
  email: string;
  isInterim: boolean;
  creationDate: Date;

  constructor(userResultReference: UserResultReference) {
    this.uuid = userResultReference.uuid;
    this.questionnaireTitle = userResultReference.questionnaireTitle;
    this.userTitle = userResultReference.userTitle;
    this.email = userResultReference.email;
    this.isInterim = userResultReference.isInterim;
    this.creationDate = userResultReference.creationDate;
  }
}

export class User {
  username: string;
  email: string;
  // preferredUsername?: string;
  // firstName?: string;
  // lastName?: string;
  isAdmin: boolean;
  // profileSrcImg?: string;

  constructor(user: User) {
    this.username = user.username;
    this.email = user.email;
    this.isAdmin = user.isAdmin;
  }
}
