
import { aimService } from "@/services/AIMService";

aimService
  .signinRedirectCallback()
  .then(function () {
    window.location.href = "../home";
  })
  .catch(function (err) {
    err;
    // console.dir(err);
  });

export default {
  name: "sso-callback",
};
